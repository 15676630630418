<template>
    <modal ref="modalCrearPedidos" :titulo="titulo" icon="confirmar-pedido" @guardar="crearRecurrente">
        <ValidationObserver ref="validacion">
            <div class="crear-pedidos">
                <div class="w-75">
                    <ValidationProvider v-slot="{errors}" rules="required|max:35" name="descripción">
                        <label class="crear-pedidos__label" for="input-pedido ml-2 mb-1  text-general f-15">Nombre del pedido</label>
                        <el-input v-model="model.nombre" placeholder="Nombre recurrente" class="br-20" maxlength="35" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>


<script>
import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'
export default {

    data(){
        return{
            model:{
                id: null,
                nombre: '',
            },
            titulo: ''
        }
    },
    methods:{
        toggle(titulo,pedidoSeleccionado){

            this.titulo = titulo
            this.model.id = pedidoSeleccionado.id
            this.model.nombre = pedidoSeleccionado.nombre
            this.$refs.modalCrearPedidos.toggle()
        },
        async crearRecurrente(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                if (this.model.id){
                    const {data} = await PedidosRecurrentes.editarRecurrente(this.model.id,this.model)
                    this.$emit('pedidoCreado', data.data, true)
                    this.notificacion('Mensaje', data.message, 'success')
                    this.model.nombre = ''

                } else {

                    const {data} = await PedidosRecurrentes.crearRecurrente(this.model)
                    this.$emit('pedidoCreado', data.data)
                    this.notificacion('Mensaje', data.message, 'success')
                    this.model.nombre = ''
                }

                this.$refs.modalCrearPedidos.toggle()

            } catch (error){
                this.error_catch(error)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
        .crear-pedidos{
            display: flex;
            justify-content: center;
           &__input{
               width:329px;
               height: 44px;
               border:1px solid #DBDBDB;
               border-radius: 12px;
               padding:  0 10px;
               outline:0;
               &::placeholder{
                   color: #DBDBDB;
               }

           }

        }
</style>
